var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xxl flex-grow-1 container-p-y"},[_c('div',{staticClass:"row g-6"},[_c('div',{staticClass:"col-xl-3 col-sm-6"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-header pb-0"},[_c('h5',{staticClass:"mb-3 card-title"},[_vm._v("المزرعة الذكية")]),_c('p',{staticClass:"mb-0 text-body"},[_vm._v("عدد المزارع ")]),_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.Info.farmesCount))])]),_c('br'),_c('div',{staticClass:"card-header pb-0"},[_c('p',{staticClass:"mb-0 text-body"},[_vm._v("انواع النخيل في المزرعة ")]),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatNumber(_vm.Info.palmsTypeCount)))])])])]),_c('div',{staticClass:"col-xl-3 col-sm-6"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-header"},[_vm._m(0),_c('h4',{staticClass:"card-title mb-1"},[_vm._v(_vm._s(_vm.formatNumber(_vm.Info.fertilizationCompaniesCount)))])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_vm._m(1),_c('h5',{staticClass:"mb-0 pt-1"},[_vm._v(_vm._s(_vm.formatNumber(_vm.Info.pestsCount)))]),_c('small',{staticClass:"text-muted"})]),_vm._m(2),_c('div',{staticClass:"col-4 text-end"},[_vm._m(3),_c('h5',{staticClass:"mb-0 pt-1"},[_vm._v(_vm._s(_vm.formatNumber(_vm.Info.pesticidesCount)))]),_c('small',{staticClass:"text-muted"})])]),_vm._m(4)])])]),_c('div',{staticClass:"col-md-6 order-md-3 order-lg-0"},[_c('div',{staticClass:"card h-100"},[_vm._m(5),_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-12 col-sm-4 col-md-12 col-lg-4"},[_c('div',{staticClass:"mt-lg-4 mt-lg-2 mb-lg-6 mb-2"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.formatNumber(_vm.Info.palmsCount)))])])]),_c('div',{staticClass:"col-12 col-sm-8 col-md-12 col-lg-8"},[_c('SupportTracker',{attrs:{"series":_vm.Info.palmsCount*1}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"mb-0 text-body"},[_vm._v("عدد شركات التسميد ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex gap-2 align-items-center mb-2"},[_c('span',{staticClass:"badge bg-label-info p-1 rounded"},[_c('i',{staticClass:"ti ti-chart-area ti-sm"})]),_c('p',{staticClass:"mb-0"},[_vm._v("الافات")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"divider divider-vertical"},[_c('div',{staticClass:"divider-text"},[_c('span',{staticClass:"badge-divider-bg bg-label-secondary"},[_vm._v("VS")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex gap-2 justify-content-end align-items-center mb-2"},[_c('p',{staticClass:"mb-0"},[_vm._v("المبيدات")]),_c('span',{staticClass:"badge bg-label-primary p-1 rounded"},[_c('i',{staticClass:"ti ti-chart-area-line ti-sm"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center mt-6"},[_c('div',{staticClass:"progress w-100",staticStyle:{"height":"10px"}},[_c('div',{staticClass:"progress-bar bg-info",staticStyle:{"width":"70%"},attrs:{"role":"progressbar","aria-valuenow":"70","aria-valuemin":"0","aria-valuemax":"100"}}),_c('div',{staticClass:"progress-bar bg-primary",staticStyle:{"width":"30%"},attrs:{"role":"progressbar","aria-valuenow":"30","aria-valuemin":"0","aria-valuemax":"100"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"card-title mb-0"},[_c('h5',{staticClass:"mb-1"},[_vm._v("عدد المستخدمين في النظام ")])])])
}]

export { render, staticRenderFns }