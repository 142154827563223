<script>
    import blockUI from './BlockUIService.js';
    import moment from 'moment';
    import DataService from './DataService.js';
    import CryptoJS from 'crypto-js';
    export default {
        data() {
            return {
                loginDetails: '',

                Cities: [],
                Municipalities: [],
                FertilizationCompanies: [],
                Fertilization: [],
                PalmsType: [],

                PalmsCareType: [],
                PestsCompanies: [],
                Pests: [],
                ProductionsClassification: [],
                PesticidesCompanies: [],
                Pesticides: [],

                Users: [],





                /*Publeish*/
                //ServerUrl: 'https://dashboard.platform.traneem.ly',

                /*Local*/
                ServerUrl: 'http://localhost:5000',
                Facebock: 'https://www.facebook.com/p/Traneem-100064940462078/?locale=ar_AR',
                Instagram: 'https://www.instagram.com/traneem5__/',
                TraneemPhone: '+218 94 457 81 48',
                TraneemEmail: 'info@traneem.ly',
                PlatFormPass: 'Traneem!@Platformv1',


                WebSiteName: 'Smart Farm',
                BuildFor: 'Smart Farm',
                logoPath: '/assets/img/1/3.png',
                CoverPath: '/assets/img/illustrations/auth-register-illustration-dark.png',
                PdfPath: '/assets/img/icons/misc/pdf1.png',

            }
        },
        methods: {

            encrypt: function encrypt(data, SECRET_KEY) {
                var dataSet = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
                dataSet = dataSet.toString();
                return dataSet;
            },
            decrypt: function decrypt(data, SECRET_KEY) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = JSON.parse(data.toString(CryptoJS.enc.Utf8));
                return data;
            },

            ClearForm(ruleForm) {
                for (const key in ruleForm) {
                    ruleForm[key] = ''; // Reset to an empty string
                }
            },

            async CheckLoginStatus() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    const res = await DataService.IsLoggedin();
                    if (!res.data)
                        this.logout();
                } catch (error) {
                    this.logout();
                }
            },

            async logout() {
                localStorage.removeItem('currentUser-client');
                localStorage.clear();
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                
                this.$http.Logout()
                    .then(() => {
                        window.location.href = "/Login";
                    })

                window.location.href = "/Login";
            },


            formatNumber(num) {
                if (num) {
                    if (num <= 0) {
                        return 0;
                    } else {
                        return new Intl.NumberFormat('en-US').format(num); // Format the number
                    }
                } else {
                    return 0;
                }
            },

            moment(date) {
                if (date === null) {
                    return "------";
                }
                // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
                return moment(date).format('DD/MM/YYYY');
            },

            momentTime (date) {
                if (date === null) {
                    return "------";
                }
                // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
                return moment(date).format('DD/MM/YYYY ||  h:mm a');
            },

            ChangeDate(date) {
                    if (date === null) {
                        return "فارغ";
                    }
                    return moment(date).format("YYYY-MM-DD");
            },




            //Get Dictionaries
            async GetAllCities() {
                this.Cities = [],
                blockUI.Start();
                try {
                    const res = await DataService.GetAllCities();
                    this.Cities = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllMunicipalities(Id) {
                this.Municipalities = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllMunicipalities(Id);
                    this.Municipalities = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllFertilizationCompanies() {
                this.FertilizationCompanies = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllFertilizationCompanies();
                    this.FertilizationCompanies = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllFertilization(Id) {
                this.Fertilization = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllFertilization(Id);
                    this.Fertilization = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPalmsType() {
                this.PalmsType = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPalmsType();
                    this.PalmsType = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPalmsCareType() {
                this.PalmsCareType = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPalmsCareType();
                    this.PalmsCareType = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPestsCompanies() {
                this.PestsCompanies = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPestsCompanies();
                    this.PestsCompanies = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },
            async GetPests() {
                this.Pests = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetPestsAll();
                    this.Pests = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPests(Id) {
                this.Pests = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPests(Id);
                    this.Pests = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllProductionsClassification() {
                this.ProductionsClassification = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllProductionsClassification();
                    this.ProductionsClassification = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPesticidesCompanies() {
                this.PesticidesCompanies = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPesticidesCompanies();
                    this.PesticidesCompanies = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPesticides(Id) {
                this.Pesticides = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPesticides(Id);
                    this.Pesticides = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },






            async GetAllUsers() {
                this.Users = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllUsers();
                    this.Users = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },


            async GetAllDistributors() {
                this.Distributors = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllDistributors();
                    this.Distributors = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },






           
        }
    }
</script>
